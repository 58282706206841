@import 'styles/includes.scss';

.ExtendedByline {
    max-width: 100%;
    padding-bottom: 4rem;

    overflow: hidden;

    @include media(ml) {
        max-width: 40.4rem;
        width: 100%;
        padding-bottom: 0;
    }

    &__Background {
        &--Orange50 {
            background-color: $colorOrange50;
        }

        &--Blue50 {
            background-color: $colorBlue50;
        }

        &--Purple50 {
            background-color: $colorPurple50;
        }

        &--Green50 {
            background-color: $colorGreen50;
        }
    }

    &__Background.ExtendedByline--Small {
        padding: $spacingL;

        @include media(l) {
            padding: $spacingXl;
        }
    }

    &__Background.ExtendedByline--Large {
        padding: $spacingL $spacingL;

        @include media(m) {
            padding: $spacingXl;
        }

        @include media(l) {
            padding-bottom: $spacingL;
        }
    }

    &--Large > &__BylineContainer {
        //padding-bottom: 0.8rem;
    }

    &__TextContainer {
        display: none;
    }

    &--Large > .ExtendedByline__TextContainer {
        display: block;
        font-family: $fontBrand;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        margin-top: 2.6rem;
    }

    &__Headline {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.01rem;
        color: $colorBlack800;
        padding-bottom: $spacingXs;
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 1.65;
        color: $colorBlack750;
        padding-bottom: $spacingXl;
        font-weight: normal;

        @include media(l) {
            padding-bottom: $spacingL * 2;
        }
    }
}
