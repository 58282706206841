@import 'styles/includes.scss';
@import 'styles/variables.scss';
@import 'styles/extensions.scss';

.CardList {
    $root: &;
    background-color: $colorBlack800;
    font-family: $fontBrand;
    color: $colorWhite;
    position: relative;
    overflow: hidden;

    &--White {
        background-color: $colorWhiteBackground;
        color: $colorBlack800;
    }

    &--Half {
        background-color: $colorWhiteBackground;
        color: $colorBlack800;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 50%;
            background-color: $colorBlack800;
        }
    }

    &:not(&--Featured) {
        padding-top: 4.4rem;
        padding-bottom: 0;

        @include media(m) {
            padding-top: 6.4rem;
            padding-bottom: 2rem;
        }

        @include media(l) {
            padding-top: 8.3rem;
            padding-bottom: 3rem;
        }
    }

    &--NoPadding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &__ContentContainer {
        @extend %container;

        padding-bottom: 4rem;

        transform: translateY(50px);
        opacity: 0;
        transition: transform 0.4s ease-out, opacity 0.5s ease-out;
        transition-delay: $transitionDelayFast;
        position: relative;
        z-index: 1;

        &--isVisible {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &__TextContainer {
        position: relative;

        &--Shape {
            padding-left: 2rem;
            min-height: 9.5rem;
            margin-bottom: 2rem;

            @include media(ml) {
                padding-left: 4rem;
                min-height: 10.7rem;
            }

            &:after {
                bottom: 0;
                left: 0;
                content: ' ';
                border-top: 112px solid $colorGreen50;
                border-left: 95px solid transparent;
                width: 0;
                position: absolute;
                transform: rotate(180deg);
                z-index: -1;

                #{$root}--Purple & {
                    border-top-color: $colorPurple50;
                }

                #{$root}--Green & {
                    border-top-color: $colorGreen50;
                }

                #{$root}--Blue & {
                    border-top-color: $colorBlue50;
                }

                #{$root}--Orange & {
                    border-top-color: $colorOrange50;
                }

                @include media(ml) {
                    border-top-width: 141px;
                    border-left: 120px solid transparent;
                }
            }
        }
    }

    &__TitleContainer {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        @include media(l) {
            flex-flow: row;
        }
    }

    &__Title {
        font-weight: bold;
        font-size: 2.6rem;
        line-height: 1.32;
        display: block;
        letter-spacing: 0.4px;
        margin-bottom: 2rem;

        @include media(l) {
            font-size: 3.5rem;
            line-height: 1.31;
            letter-spacing: 0.8px;
        }
    }

    &__Link {
        color: $colorWhite;
        border-bottom: 1px solid $colorWhite;
        text-decoration: none;
        white-space: nowrap;
    }

    &__PreambleContainer {
        margin-bottom: 0;
    }

    &__Preamble {
        font-size: 1.6rem;
        width: 100%;
        line-height: 1.65;
        margin-bottom: 2rem;
        color: $colorGray100;

        @include media(l) {
            width: 50%;
            padding-right: $spacingXl;
        }

        #{$root}--White & {
            color: $colorBlack800;
        }

        #{$root}--Half & {
            color: $colorBlack800;
        }
    }

    &__Cards {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-right: 0;
        padding-right: $spacingXl;
        padding-left: 16px;
        width: 100%;

        @include media(s) {
            padding-left: calc((100% - #{map-get($maxwidths, s)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, s)} + calc((100% - #{map-get(
                                    $maxwidths,
                                    s
                                )}))
            );
        }

        @include media(m) {
            padding-left: calc((100% - #{map-get($maxwidths, m)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, m)} + calc((100% - #{map-get(
                                    $maxwidths,
                                    m
                                )}))
            );
        }

        @include media(ml) {
            padding-left: calc((100% - #{map-get($maxwidths, ml)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, ml)} + calc((100% - #{map-get(
                                    $maxwidths,
                                    ml
                                )}))
            );
        }

        @include media(l) {
            overflow: visible;
            padding-left: calc((100% - #{map-get($maxwidths, l)}) / 2);
            padding-right: calc((100% - #{map-get($maxwidths, l)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, l)} + calc((100% - #{map-get($maxwidths, l)}) / 2)
            );
        }

        @include media(xl) {
            padding-left: calc((100% - #{map-get($maxwidths, xl)}) / 2);
            padding-right: calc((100% - #{map-get($maxwidths, xl)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, xl)} + calc((100% - #{map-get($maxwidths, xl)}) / 2)
            );
        }
    }

    .Card,
    .CardEvent {
        min-width: 25.3rem;

        @include media(l) {
            min-width: 29.7rem;
        }
    }

    .Card {
        @include media(xl) {
            min-width: 34.2rem;
        }

        &__Title {
            min-height: 93px;
        }
    }

    &__Featured {
        margin-top: 35px;
        display: flex;
        flex-direction: column;

        @include media(l) {
            margin-top: 38px;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__FeaturedCards {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include media(m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__Button {
        display: inline-block;
        text-align: left;
        margin-bottom: 1.4rem;
    }

    &--SpaceBetween {
        .swiper-container {
            width: 100% !important;
        }

        .swiper-wrapper {
            display: flex !important;
            justify-content: space-between !important;
        }
    }

    .CardList_CardList--Featured, .CardList--Featured {
        background: transparent;
    }
}
