@import 'styles/includes.scss';
@import 'styles/keyframes.scss';

.HeroSlim {
    position: relative;
    width: 100%;
    height: 37.6rem;
    margin-bottom: 2.4rem;

    @include media(m) {
        height: 44.8rem;
        margin-bottom: 6.2rem;
    }

    @include media(ml) {
        margin-left: auto;
        margin-right: auto;
        padding: 0 $spacingXl * 3;
    }

    @include media(l) {
        padding: 0 $spacingXl * 4;
    }

    @include media(xl) {
        max-width: (map-get($maxwidths, xl));
        padding: 0;
    }

    &__Container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: fadeInAndScale .6s;
        transform: scale(1.05);
    }

    &__Caption {
        font-family: $fontBrand;
        padding: 0.4rem 1.6rem;
        color: $colorBlack800;
        font-size: 1rem;
        line-height: 1.55;
        text-align: right;

        @include media(m) {
            padding: 0.4rem;
        }
    }
}
