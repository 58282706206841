@import 'styles/includes.scss';
@import 'styles/variables.scss';

.CardListLocals {
    $root: &;
    font-family: $fontBrand;
    display: flex;
    flex-direction: column;
    background-color: $colorGray10;
    padding-top: 5.1rem;

    &__IntroContainer {
        @extend %container;
        max-width: $maxWidthText;
        position: relative;
        z-index: 1;
        margin-bottom: 2.2rem;
        margin-top: 0.8rem;
        min-height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        transform: translateY(100px);
        opacity: 0;
        transition: all 0.2s ease-out;
        transition-delay: $transitionDelayFast;

        #{$root}--isVisible & {
            opacity: 1;
            transform: translateY(0px);
        }

        @include media(ml) {
            min-height: 120px;
            margin-bottom: 4rem;
        }

        @include media(l) {
            padding-left: 8.6rem;
        }

        &:after {
            bottom: 0;
            left: 1.6rem;
            content: ' ';
            border-top: 112px solid $colorPurple50;
            border-left: 95px solid transparent;
            width: 0;
            position: absolute;
            transform: rotate(180deg);
            z-index: -1;

            #{$root}--Green & {
                border-top-color: $colorGreen50;
            }
            #{$root}--Blue & {
                border-top-color: $colorBlue50;
            }
            #{$root}--Orange & {
                border-top-color: $colorOrange50;
            }

            @include media(ml) {
                border-top-width: 141px;
                border-left: 120px solid transparent;
                left: $spacingXl * 3 - 10px;
            }

            @include media(l) {
                left: $spacingXl * 4 - 30px;
                top: calc(50% - 72px);
            }
        }
    }

    &__Title {
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.32;
        letter-spacing: 0.04rem;
        padding-left: 1.6rem;
        color: $colorBlack;
        margin-bottom: 0.4rem;

        @include media(l) {
            padding-left: 0;
            font-size: 3.5rem;
            line-height: 1.31;
            margin-bottom: 1rem;
            letter-spacing: 0.08rem;
        }
    }

    &__Preamble {
        max-width: 430px;
        padding-left: 1.7rem;
        color: $colorBlack800;

        @include media(ml) {
            padding-left: 5.5rem;
            margin-bottom: 2rem;
        }

        @include media(l) {
            max-width: 530px;
        }
    }

    &__CardWrapper {
        position: relative;
        padding-bottom: 2rem;

        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        transition-delay: $transitionDelayFast;

        #{$root}--isVisible & {
            opacity: 1;
            transform: translateY(0px);
        }

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            bottom: 0;
            background-color: $colorBlack800;
            transition: height 1s ease-out;
            transition-delay: $transitionDelayFast;

            #{$root}--isVisible & {
                height: 50%;
            }
        }
    }

    &__CardContainer {
        @extend %container;
    }

    &__LinkContainer {
        display: flex;
        justify-content: center;
        background-color: $colorBlack800;
        padding-top: 3.6rem;
        padding-bottom: 4.8rem;

        @include media(l) {
            padding-bottom: 8rem;
        }
    }

    &--SpaceBetween {
        .swiper-container {
            width: 100% !important;
        }
        .swiper-wrapper {
            display: flex !important;
            justify-content: space-between !important;
        }
    }

    &__Button {
        opacity: 0;
        transition: opacity 0.3s ease-out;
        transition-delay: $transitionDelayFast;

        #{$root}--isVisible & {
            opacity: 1;
        }
    }
}
